<template>
    <div class="alert alert-warning alert-dismissible fade show sticky-banner" role="alert">
        <strong>Hola!!! Gracias por visitarnos!</strong> Este es un diseño de como se veria tu tienda gratuita!!  Este demo no ofrece ninguna funcionalidad.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
</template>
<style>
.sticky-banner {
    position: -webkit-sticky; /* Soporte para Safari */
    position: sticky;
    top: 0;
    z-index: 1030; /* Asegura que el banner esté sobre otros elementos */
    width: 100%; /* Asegura que el banner ocupe todo el ancho */
}
</style>