import { createRouter, createWebHistory } from 'vue-router';
import WebView from '../views/WebView.vue';
import PreciosView from '../views/PreciosView.vue';
import PrivacyView from '../views/PrivacyView.vue';
import TerminosView from '../views/TerminosView.vue';
import DemoView from '../views/DemoView.vue';
import TiendasView from '../views/TiendasView.vue';
import CheckoutComponent from '../components/CheckoutComponent.vue'; 
import SuccesPayement from '../components/SuccesPayement.vue'; 
import ArticuloView from '../views/ArticuloView.vue'; 


const routes = [
    { path: '/', component: WebView },
    { path: '/precios', component: PreciosView },
    { path: '/privacy', component: PrivacyView },
    { path: '/terms', component: TerminosView },
    { path: '/demo/:demo', component: DemoView },
    { path: '/:tienda', component: TiendasView },
    {
      path: '/:tienda/checkout',
      name: 'checkout',
      component: CheckoutComponent,
      props: true  
    },
    { path: '/success/:orden', component: SuccesPayement },
    { path: '/:tienda/:item', name: 'ArticuloView', component: ArticuloView },

  ];

const router = createRouter({
    history: createWebHistory(),
    routes
  });

export default router;