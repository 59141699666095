<template>
    <Navbar/>
    <DividerComponent />
    <DescripComponent />

    <section class="has-divider bg-primary-alt">
      <div class="container pt-5">
        <div class="row justify-content-between align-items-center o-hidden">
          <div class="col-md-6 col-lg-5">
            <div class="d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
              <div class="process-circle bg-primary"></div>
              <div class="ml-3">
                <h5>1. Crea tu cuenta.</h5>
                <p>
                    Únete ahora y comienza tu viaje empresarial en minutos.
                </p>
              </div>
            </div>
            <div class="d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
              <div class="process-circle bg-primary"></div>
              <div class="ml-3">
                <h5>2. Registra tus productos</h5>
                <p>
                    Da vida a tus productos y llega a nuevos clientes.
                </p>
              </div>
            </div>
            <div class="d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
              <div class="process-circle bg-primary"></div>
              <div class="ml-3">
                <h5>3. Comienza a vender</h5>
                <p>
                    ¡Es hora de hacer crecer tu negocio en línea!
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 aos-init aos-animate" data-aos="fade-left">
            <img src="../assets/img/saas-4.svg" alt="Image">
          </div>
        </div>
      </div>
      <div class="divider">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="96px" viewBox="0 0 100 100" version="1.1" preserveAspectRatio="none" class="injected-svg" data-src="assets/img/dividers/divider-2.svg">
    <path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
</svg>
      </div>
    </section>
    <SliderComponent />
    <PowerTeamComponent />
    <FooterComponent />
</template>
<script>
import Navbar from '@/components/NavBar.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import DescripComponent from '@/components/DescripComponent.vue';
import SliderComponent from '@/components/SliderComponent.vue';
import PowerTeamComponent from '@/components/PowerTeamComponent';
import FooterComponent from '@/components/FooterComponent.vue';
export default {
  /*
  mounted() {

    this.updateMeta();
  },*/
    components: {
        Navbar,
        DividerComponent,
        DescripComponent,
        SliderComponent,
        PowerTeamComponent,
        FooterComponent,
    }/*,
    methods: {
      updateMeta() {
      const metaTags = [
        { name: 'classification', content: 'Desarrollo de Software'},
        { name: 'publisher', content: 'Desarrollo de Software, saldi.me'},
        { name: 'organization', content: 'saldi.me'},
        { name: 'sitedomain', content: 'www.saldi.me'},
        { name: 'description', content: 'Saldi es una plataforma fácil de usar que te permite crear tu tienda online gratuita y empezar a vender online.' },
        { property: 'og:title', content:'Saldi - Tu tienda en internet' },
        { property: 'og:description', content: 'Saldi es una plataforma fácil de usar que te permite crear tu tienda online gratuita y empezar a vender online.' },
        { property: 'og:image', content: 'https://raw.githubusercontent.com/Saldi-Shop/web/main/img/image.png' },
        { property: 'og:url', content: `https://saldi.me/` },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Saldi - Tu tienda en internet' },
        { name: 'twitter:description', content: 'Saldi es una plataforma fácil de usar que te permite crear tu tienda online gratuita y empezar a vender online.' },
        { name: 'twitter:image', content: 'https://raw.githubusercontent.com/Saldi-Shop/web/main/img/image.png' }
      ];
      
      metaTags.forEach(tag => {
        let metaTag = document.querySelector(`meta[${Object.keys(tag)[0]}="${Object.values(tag)[0]}"]`);
        if (!metaTag) {
          metaTag = document.createElement('meta');
          document.head.appendChild(metaTag);
        }
        metaTag.setAttribute(Object.keys(tag)[0], Object.values(tag)[0]);
        metaTag.setAttribute('content', tag.content);
      });
    }
    }*/
}
</script>
<style scoped>
  @import '../assets/css/theme-saas.min.css'; 
</style>
