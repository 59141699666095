<template>
       <section class="bg-primary-alt">
      <div class="container">
        <div class="row mb-4">
          <div class="col">
            <h2 class="h1">Una plataforma sincilla para todos</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 aos-init" data-aos-delay="100">
            <div class="card card-body">
              <div class="icon-round mb-3 mb-md-4 bg-primary">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary" data-src="assets/img/icons/theme/communication/chat-check.svg">
                    <title>Icon For Chat-check</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                        <path d="M4.875,20.75 C4.63541667,20.75 4.39583333,20.6541667 4.20416667,20.4625 L2.2875,18.5458333 C1.90416667,18.1625 1.90416667,17.5875 2.2875,17.2041667 C2.67083333,16.8208333 3.29375,16.8208333 3.62916667,17.2041667 L4.875,18.45 L8.0375,15.2875 C8.42083333,14.9041667 8.99583333,14.9041667 9.37916667,15.2875 C9.7625,15.6708333 9.7625,16.2458333 9.37916667,16.6291667 L5.54583333,20.4625 C5.35416667,20.6541667 5.11458333,20.75 4.875,20.75 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                        <path d="M2,11.8650466 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.23590829,11 3.04485894,11.3127315 2,11.8650466 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 L15,9 C15.5522847,9 16,8.55228475 16,8 C16,7.44771525 15.5522847,7 15,7 L6,7 Z" fill="#000000"></path>
                    </g>
                </svg>
              </div>
              <h4>Construido para todos</h4>
              <p>
                Esta plataforma es para todos. Negocio pequeño o negocio grande aqui puedes vender tus articulos.
              </p>
              <!--
              <a href="#">Learn More</a>
              -->
            </div>
          </div>
          <div class="col-md-4 aos-init" data-aos-delay="200">
            <div class="card card-body">
              <div class="icon-round mb-3 mb-md-4 bg-primary">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary" data-src="assets/img/icons/theme/shopping/wallet-3.svg">
                    <title>Icon For Wallet#3</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                        <path d="M4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 Z" fill="#000000" opacity="0.3"></path>
                        <path d="M18.5,11 L5.5,11 C4.67157288,11 4,11.6715729 4,12.5 L4,13 L8.58578644,13 C8.85100293,13 9.10535684,13.1053568 9.29289322,13.2928932 L10.2928932,14.2928932 C10.7456461,14.7456461 11.3597108,15 12,15 C12.6402892,15 13.2543539,14.7456461 13.7071068,14.2928932 L14.7071068,13.2928932 C14.8946432,13.1053568 15.1489971,13 15.4142136,13 L20,13 L20,12.5 C20,11.6715729 19.3284271,11 18.5,11 Z" fill="#000000"></path>
                        <path d="M5.5,6 C4.67157288,6 4,6.67157288 4,7.5 L4,8 L20,8 L20,7.5 C20,6.67157288 19.3284271,6 18.5,6 L5.5,6 Z" fill="#000000"></path>
                    </g>
                </svg>
              </div>
              <h4>Diseño sencillo</h4>
              <p>
                Sabemos lo engorroso que puede ser administrar un sitio web. No te preocupes solo registrate y comparte.
             </p>
             <!--
              <a href="#">Learn More</a>
              -->
            </div>
          </div>
          <div class="col-md-4 aos-init" data-aos-delay="300">
            <div class="card card-body">
              <div class="icon-round mb-3 mb-md-4 bg-primary">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary" data-src="assets/img/icons/theme/food/sushi.svg">
                    <title>Icon For Sushi</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                        <path d="M7,17 C9.28100068,17 11,15.9686004 11,15 C11,14.0313996 9.28100068,13 7,13 C4.71899932,13 3,14.0313996 3,15 C3,15.9686004 4.71899932,17 7,17 Z M11.5,21.3092376 C10.6896396,22.3100893 8.97910086,23 7,23 C4.23857625,23 2,21.6568542 2,20 C2,17.2642141 2,15.5975474 2,15 C2,13.3431458 4.23857625,12 7,12 C8.97910086,12 10.6896396,12.6899107 11.5,13.6907624 C12.3103604,12.6899107 14.0208991,12 16,12 C18.7614237,12 21,13.3431458 21,15 C21,15.3356863 21,17.0023529 21,20 C21,21.6568542 18.7614237,23 16,23 C14.0208991,23 12.3103604,22.3100893 11.5,21.3092376 Z M16,17 C18.2810007,17 20,15.9686004 20,15 C20,14.0313996 18.2810007,13 16,13 C13.7189993,13 12,14.0313996 12,15 C12,15.9686004 13.7189993,17 16,17 Z" fill="#000000" fill-rule="nonzero"></path>
                        <path d="M19.6608485,2.02106698 C19.8359854,2.52970126 19.5656323,3.08400728 19.056998,3.25914411 C19.0489211,3.2619252 19.040808,3.26459992 19.0326607,3.26716758 L2.33565766,8.52933019 C2.07027996,8.61296559 1.78658327,8.46994761 1.6959956,8.20686191 C1.60540793,7.94377622 1.74091331,7.65641538 2.00152489,7.55893815 L18.3986537,1.42587734 C18.9025046,1.23742064 19.4637309,1.49309823 19.6521876,1.99694922 C19.6551802,2.00495015 19.6580674,2.01299009 19.6608485,2.02106698 Z M20.9715106,6.55164469 C21.0649233,7.08141428 20.7111861,7.58660332 20.1814165,7.68001599 C20.173004,7.68149934 20.1645723,7.68287196 20.1561237,7.68413349 L2.84150489,10.2695234 C2.56631096,10.3106149 2.30848,10.1249778 2.26016328,9.85096006 C2.21184657,9.57694235 2.39063678,9.31431712 2.6632886,9.25880863 L19.8179636,5.76633204 C20.3450925,5.65901521 20.8594123,5.99934012 20.9667291,6.52646899 C20.9684333,6.53483956 20.9700272,6.54323218 20.9715106,6.55164469 Z M16,16 C15.1715729,16 14.5,15.5522847 14.5,15 C14.5,14.4477153 15.1715729,14 16,14 C16.8284271,14 17.5,14.4477153 17.5,15 C17.5,15.5522847 16.8284271,16 16,16 Z M7,16 C6.17157288,16 5.5,15.5522847 5.5,15 C5.5,14.4477153 6.17157288,14 7,14 C7.82842712,14 8.5,14.4477153 8.5,15 C8.5,15.5522847 7.82842712,16 7,16 Z" fill="#000000" opacity="0.3"></path>
                    </g>
                </svg>
              </div>
              <h4>Estamos contigo</h4>
              <p>
                Necesitas ayuda para comenzar?, no te preocupes. Contactanos y te ayudamos.</p>
                <!--
              <a href="#">Learn More</a>
              -->
            </div>
          </div>
        </div>
      </div>
    </section>
</template>