<template>
  <Navbar/>
    <div class="not-found">
 
      <div class="container">
        <div class="row justify-content-center mb-md-6">
          <div class="col-auto">
            <a href="index.html">
              <img src="../assets/img/logo-1.jpg" alt="Saldi">
            </a>
          </div>
        </div>
        <div class="row text-center py-6">
          <div class="col layer-2">
            <h1 class="display-1 mb-0">404</h1>
            <h2 class="h1">Página no encontrada</h2>
            <div class="lead mb-4">Lo sentimos, la tienda que estás buscando no existe.</div>
            <a class="btn btn-primary btn-lg" href="/">Regresar a Saldi.mx</a>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </template>
  
  <script>
  import Navbar from '@/components/NavBar.vue';
  import FooterComponent from '@/components/FooterComponent.vue';
  export default {
    name: 'NotFound',
    components: {
        Navbar,
        FooterComponent,
    },
    created() {
      document.title = "Saldi - 404";
    }
  }
  </script>
  
  <style scoped>
  .not-found {
    text-align: center;
    margin-top: 50px;
  }
  </style>