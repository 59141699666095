import { createStore } from 'vuex';

export default createStore({
  state: {
    productosCarrito: []
  },
  mutations: {
    setProductosCarrito(state, productos) {
      state.productosCarrito = productos;
    },
    guardarDetallesArticulo(state, detalles) {
      state.detallesArticulo = detalles;
    }
  },
  actions: {
    actualizarCarrito({ commit }, productos) {
      commit('setProductosCarrito', productos);
    }
  },
  getters: {
    productosCarrito: state => state.productosCarrito
  }
});
