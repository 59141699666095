<template>
    <Navbar/>
    <!---->
    <section class="pt-0" data-reading-position="">
      <div class="container">
        <div class="row">
          <div class="col-auto">
          </div>
          <div class="col">
            <div class="row justify-content-center">
              <div class="col-xl-7 col-lg-8">
                <span class="text-muted">Enero 2024</span>
                <h1 class="my-2">Politicas de Privacidad</h1>
             <!--   <div>Version 1.2, Revision 6</div> -->
                <hr>
                <article class="article">
                  <p class="lead">
                    En Saldi, nos tomamos la privacidad de nuestros usuarios en serio. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos la información personal que usted nos proporciona.
                  </p>
                  <p>
                    Al utilizar los servicios de Saldi, usted acepta las prácticas descritas en esta política.
                  </p>
                  <h4>Recopilación de Información</h4>
                  <ul>
                    <li>Información de Registro: Cuando crea una cuenta en Saldi, recopilamos información personal como su nombre, dirección de correo electrónico y contraseña.</li>
                    <li>Información del Perfil: Puede elegir proporcionar información adicional en su perfil, como una foto de perfil, número de teléfono u otros detalles personales.</li>
                    <li>Información de la Tienda: Si crea una tienda en Saldi, recopilamos información sobre los productos que vende y los detalles de la tienda.</li>
                    <li>Información de Pago: Cuando realiza pagos a través de Saldi, podemos recopilar información de pago, como información de tarjeta de crédito o detalles de la cuenta bancaria.</li>
                    <li>Información de Uso: Recopilamos información sobre cómo utiliza nuestro sitio web y servicios, como las páginas que visita, los productos que ve y las interacciones con otros usuarios.</li>
                  </ul>

                  <h4>Uso de la Información</h4>
                  <p>
                    Utilizamos la información recopilada para los siguientes propósitos:
                  </p>
                  <ul>
                    <li>Facilitar la creación de cuentas y el inicio de sesión.</li>
                    <li>Personalizar su experiencia en Saldi, incluyendo la presentación de productos relevantes.</li>
                    <li>Procesar transacciones y pagos.</li>
                    <li>Comunicarnos con usted, incluyendo notificaciones sobre su cuenta y servicio al cliente.</li>
                    <li>Mejorar nuestros servicios y la experiencia del usuario.</li>
                  </ul>
                  <!--
                  <figure>
                    <img src="assets/img/article-9.jpg" alt="Image" class="rounded">
                    <figcaption>A caption to describe the image</figcaption>
                  </figure>
                  -->
                  <!--
                  <p>
                    Aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
                  </p>
                  <blockquote class="bg-primary-alt">
    <div class="h3 mb-2">“We couldn’t have done it without the hard-working team from Leap.”</div>
    <span class="text-small text-muted">– Harvey Dent (via Tareq I.)</span>
    <a href="#" class="btn btn-primary btn-sm">
      <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon" data-src="assets/img/icons/social/twitter.svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Twitter icon</title><path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"></path></svg>
      <span>Tweet</span>
    </a>
  </blockquote>
  -->
                  <h4>Compartir Información</h4>
                  <p>No vendemos ni alquilamos su información personal a terceros. Sin embargo, podemos compartir su información con terceros de la siguiente manera:</p>
                  <ul>
                    <li>Con Proveedores de Servicios: Podemos compartir información con terceros que nos ayudan a proporcionar servicios, como procesadores de pagos y servicios de entrega.</li>
                    <li>Con Otros Usuarios: Si usted es un vendedor, podemos compartir información de su tienda con compradores, como su nombre de tienda y productos.</li>
                    <li>Cumplimiento Legal: Podemos divulgar información cuando sea necesario para cumplir con la ley, proteger nuestros derechos legales o responder a una solicitud legal.</li>
                  </ul>
                </article>
                <hr>
                <h6>Cambios en esta Política</h6>
                <small>Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Cualquier cambio significativo se comunicará a través de nuestro sitio web o por correo electrónico.</small>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!---->
    <FooterComponent />
</template>
<script>
import Navbar from '@/components/NavBar.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
    components: {
        Navbar,
        FooterComponent,
    }
}
</script>
<style>

</style>