<template>
    <section class="bg-primary text-light has-divider">
      <div class="divider flip-y">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="96px" viewBox="0 0 100 100" version="1.1" preserveAspectRatio="none" class="injected-svg" data-src="assets/img/dividers/divider-3.svg">
     <path d="M0,0 C6.83050094,50 15.1638343,75 25,75 C41.4957514,75 62.4956597,0 81.2456597,0 C93.7456597,0 99.9971065,0 100,0 L100,100 L0,100"></path>
</svg>
      </div>
      <div class="container">
        <div class="row justify-content-center mb-0 mb-md-3">
          <div class="col-xl-6 col-lg-8 col-md-10 text-center">
            <h3 class="h1">Lleva tu negocio con Saldi a otro nivel</h3>
          </div>
        </div>
        <div class="row justify-content-center text-center">
          <div class="col-xl-6 col-lg-7 col-md-9">
            <!--
            <form class="d-md-flex mb-3 justify-content-center">
              <input type="email" class="mx-1 mb-2 mb-md-0 form-control form-control-lg" placeholder="Email Address">
              <button class="mx-1 btn btn-primary-3 btn-lg" type="submit">Get Started</button>
            </form>
            -->
            <div class="text-small text-muted mx-xl-6">
              Crea tu catalogo y compartelo en tus redes sociales.
            </div>
          </div>
        </div>
      </div>
    </section>
</template>