<template>
    <Loader v-if="loading" />
    <component v-else :is="selectedComponent" :idTienda="idTienda" :nombreTienda="nombreTienda" :tituloTienda="tituloTienda" :subTituloTienda="subTituloTienda"/>
    <NotFound v-if="notFound" />
</template>
<script>
import Loader from '@/components/LoaderComponent.vue';
import ItemTemaCero from '@/components/Temas/ItemTemaCero.vue';
import ItemTemaUno from '@/components/Temas/TemaUno/ItemTemaUno.vue';
import NotFound from '@/components/NotFound.vue'; 
import AuthService from '@/services/AuthService.js';

export default {
    components: {
        ItemTemaCero,
        ItemTemaUno,
        Loader,
        NotFound,
    },
    data() {
        return {
            selectedComponent: null,
            store: '',
            idTienda: null,
            nombreTienda: null,
            tituloTienda: null,
            subTituloTienda: null,
            loading: true, 
            notFound: false,
        }
    },
    mounted() {     
        this.store = this.$route.params.tienda;
        console.log('Nombre Tienda: ', this.store);
        this.getInfoTienda();
    },
    methods: {
        async getInfoTienda() {
            try {
            const userData = await AuthService.getInfoTienda(this.store);
            this.selectComponent(userData);
        } catch (error) {
            console.log('Error al obtener la información del usuario:', error);
            if (error.response && error.response.status === 404) {
                // Si el error es 404, establece notFound en true
                this.notFound = true;
            }
        } finally {
            this.loading = false;  
        }
        },
        selectComponent(userData) {

            console.log(userData);
            if (userData.message==="No se encontraron registros") {
                // Si userData es nulo, muestra la página de "No encontrado"
                this.notFound = true;
                return;
            }
            
        switch (userData.tema) {
            case '0':
                this.selectedComponent = ItemTemaCero;
                break;
            case '1':
                this.selectedComponent = ItemTemaUno;
                break;
            default:
                this.notFound = NotFound;
            //    console.log('Tienda no encontrada:', this.store);
                break;
            }
            this.idTienda = userData.id_tienda;
            this.nombreTienda = userData.nom_tienda;
            this.tituloTienda = userData.titulo;
            this.subTituloTienda = userData.sub_titulo;
        }
    }
}
</script>
