<template>
                <div class="col-md-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
              <div class="card card-body align-items-center ">
                <div class="pt-md-2">
                  <h4>Basico</h4>
                </div>
                <div class="d-flex align-items-start pb-md-2">
                  <span class="h3">$</span>
                  <span class="display-4 mb-0">Gratis</span>
                </div>
                <span class="text-small text-muted">al mes</span>
                <ul class="text-center list-unstyled my-2 my-md-4">
                    <li class="py-1">
                        <span>1 Tienda en linea</span>
                      </li>
                      <li class="py-1">
                        <span>6 Usuarios</span>
                      </li>
                      <li class="py-1">
                        <span>50 Productos p/t</span>
                      </li>
                      <li class="py-1">
                        <span>10 Temas</span>
                      </li>
                      <li class="py-1">
                        <span class="text-muted text-strikethrough">Soporte tecnico</span>
                      </li>
                      <li class="py-1">
                        <span class="text-muted text-strikethrough">Pagina de inicio</span>
                      </li>
                      <li class="py-1">
                        <span class="text-muted text-strikethrough">Saldi PickUp</span>
                      </li>
                      <li class="py-1">
                        <span class="text-muted text-strikethrough">Saldi Delivery</span>
                      </li>
                      <li class="py-1">
                        <span class="text-muted text-strikethrough">Dominio personalizado</span>
                      </li>
                </ul>
                  <a href="https://admin.saldi.me/register" target="_blanks" class="btn btn-outline-primary">
                    Obten el plan basico
                </a>
              </div>
            </div>
</template>