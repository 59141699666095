<template>
                <div class="col-md-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
              <div class="card card-body align-items-center shadow-3d">
                <span class="badge badge-top badge-dark">Mas Popular</span>
                <div class="pt-md-2">
                  <h4>Profesional</h4>
                </div>
                <div class="d-flex align-items-start pb-md-2">
                  <span class="h3">$</span>
                  <span class="display-4 mb-0">99</span>
                </div>
                <span class="text-small text-muted">al mes</span>
                <ul class="text-center list-unstyled my-2 my-md-4">
                    <li class="py-1">
                        <span>3 Tiendas en linea</span>
                      </li>
                      <li class="py-1">
                        <span>12 Usuarios</span>
                      </li>
                      <li class="py-1">
                        <span>100 Productos p/t</span>
                      </li>
                      <li class="py-1">
                        <span>20 Temas</span>
                      </li>
                      <li class="py-1">
                        <span>Soporte tecnico</span>
                      </li>
                      <li class="py-1">
                        <span>Pagina de inicio</span>
                      </li>
                      <li class="py-1">
                        <span class="text-muted text-strikethrough">Saldi PickUp</span>
                      </li>
                      <li class="py-1">
                        <span class="text-muted text-strikethrough">Saldi Delivery</span>
                      </li>
                      <li class="py-1">
                        <span class="text-muted text-strikethrough">Dominio personalizado</span>
                      </li>
                </ul>
                <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" data-bs-title="Disabled tooltip">
                  <button class="btn btn-primary" type="button" disabled>Obten el plan profesional</button>
                </span>
   
              </div>
            </div>
</template>