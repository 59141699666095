<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
     <div class="container px-4 px-lg-5">
         <a class="navbar-brand" :href="`/${nombreTienda}`">
             {{ nombreTienda ? nombreTienda.charAt(0).toUpperCase() + nombreTienda.slice(1) : '' }}
         </a>
         <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
         <div class="navbar-collapse collapse" id="navbarSupportedContent" style="">
             <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-4">
                 <li class="nav-item"><a class="nav-link active" aria-current="page" :href="`/${nombreTienda}`">Inicio</a></li>
             </ul>
                 <button class="btn btn-outline-dark" @click="showCarrito" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                     <i class="bi-cart-fill me-1"></i>
                     Carrito
                     <span class="badge bg-dark text-white ms-1 rounded-pill">{{totalCarrito}}</span>
                 </button>
         </div>
     </div>
 </nav>
 <!---->
 <section class="py-5">
            <div class="container px-4 px-lg-5 my-5">
                <div class="row gx-4 gx-lg-5 align-items-center">
                    <div class="col-md-6">
                        <img class="card-img-top mb-5 mb-md-0" :src="'data:image/jpg;base64,' + userData?.imageBase64" alt="...">
                    </div>
                    <div class="col-md-6">
                        <div class="small mb-1">SKU:{{ userData?.sku }} </div>
                        <h1 class="display-5 fw-bolder">{{ userData?.producto }}</h1>
                        <div class="fs-5 mb-5">
                            <span class="text-decoration-line-through">$45.00</span>
                            <span>${{ userData?.precio }}</span>
                        </div>
                        <p class="lead">{{ userData?.descripcion }}</p>
                        <div class="d-flex">
                            <input class="form-control text-center me-3" id="inputQuantity" type="num" value="1" style="max-width: 3rem">
                            <button class="btn btn-outline-dark flex-shrink-0" type="button" @click="agregarAlCarrito(userData)">
                                <i class="bi-cart-fill me-1"></i>
                                Add to cart
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 <!---->
    <footer class="py-5 bg-dark">
        <div class="container"><p class="m-0 text-center text-white">Copyright © Your Website 2023</p></div>
    </footer>
  <!---->
 <CartComponent :productos="carrito" @actualizar-carrito="actualizarCarrito" />
</template>
<script>
import CartComponent from '@/components/CartComponent.vue';
import AuthService from '@/services/AuthService.js';
export default {
  mounted() {
    // Acceder a los parámetros de la URL
    const tienda = this.$route.params.tienda;
    const item = this.$route.params.item;
    
    // Hacer lo que necesites con los parámetros (por ejemplo, mostrarlos en la consola)
    console.log('Tienda:', tienda);
    console.log('Item:', item);
  },
  data() {
        return {
            userData: null,
            loading: true,
            carrito: JSON.parse(localStorage.getItem('carritosPorTienda'))?.[this.idTienda] || [],
            totalCarrito: (JSON.parse(localStorage.getItem('carritosPorTienda'))?.[this.idTienda] || []).length
        };
    },
    props: ['idTienda', 'nombreTienda', 'tituloTienda', 'subTituloTienda'],
    components: {
        CartComponent,
    },
    methods: { 
        async agregarAlCarrito(producto) {
            this.carrito.push(producto);
            this.totalCarrito = this.carrito.length;
            //localStorage.setItem('carrito', JSON.stringify(this.carrito));
            this.actualizarLocalStorage();
            this.$emit('producto-agregado', producto);
        },
        actualizarCarrito(carritoActualizado) {
            this.carrito = carritoActualizado;
            this.totalCarrito = this.carrito.length;
            this.actualizarLocalStorage();
        //    localStorage.setItem('carrito', JSON.stringify(this.carrito));
        },
        actualizarLocalStorage() {
            let carritosPorTienda = JSON.parse(localStorage.getItem('carritosPorTienda')) || {};
            carritosPorTienda[this.idTienda] = this.carrito;
            localStorage.setItem('carritosPorTienda', JSON.stringify(carritosPorTienda));
        },
        showCarrito(){
            console.log("carrito");
        },
        verDetallesArticulo(user) {
            console.log("Detalle: ", user)
            this.$store.commit('guardarDetallesArticulo', user);
            this.$router.push({ name: 'ArticuloView', params: { tienda: this.nombreTienda, item: user.sku } });
        }
    }, async created() {
    
        document.title = this.nombreTienda ? `${this.nombreTienda} - Shop With Style` : 'Saldi - Tu tienda en internet';

        try {
            console.log("idTienda: ", this.idTienda);
            this.userData = await AuthService.getItem(this.idTienda, this.$route.params.item);
            console.log("userData:", this.userData);
            this.loading = false;
        } catch (error) {
            console.log('Error al obtener la información del usuario:', error);
        }
    },
    

}
</script>