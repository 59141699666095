<template>
    <!-- Contenido existente de tu componente padre -->
       <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container px-4 px-lg-5">
                <a class="navbar-brand" :href="`/${nombreTienda}`">
                    {{ nombreTienda ? nombreTienda.charAt(0).toUpperCase() + nombreTienda.slice(1) : '' }}
                </a>
                <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                <div class="navbar-collapse collapse" id="navbarSupportedContent" style="">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-4">
                        <li class="nav-item"><a class="nav-link active" aria-current="page" :href="`/${nombreTienda}`">Inicio</a></li>
                    </ul>
                        <button class="btn btn-outline-dark" @click="showCarrito" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                            <i class="bi-cart-fill me-1"></i>
                            Carrito
                            <span class="badge bg-dark text-white ms-1 rounded-pill">{{totalCarrito}}</span>
                        </button>
                </div>
            </div>
        </nav>
        <!---->
        <header class="bg-dark py-5">
            <div class="container px-4 px-lg-5 my-5">
                <div class="text-center text-white">
                    <h1 class="display-4 fw-bolder" style="color: #fff;">{{tituloTienda}}</h1>
                    <p class="lead fw-normal text-white-50 mb-0">{{subTituloTienda}}</p>
                </div>
            </div>
        </header>
        <!---->
        <section class="py-5">
            <div class="container px-4 px-lg-5 mt-5">
                    <div v-if="loading" class="text-center">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                 <div v-else class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">  
                    <div class="col mb-5" v-for="user in userData" :key="user.id">
                        <div class="card h-100">
                            <!-- Product image-->
                            <img class="card-img-top" :src="'data:image/jpg;base64,' + user.imageBase64" alt="...">
                            <!-- Product details-->
                            <div class="card-body p-4">
                                <div class="text-center">
                                    <!-- Product name-->
                                    <h5 class="fw-bolder">{{ user.producto }}</h5>
                                    <!-- Product price-->
                                    ${{ user.precio }}
                                </div>
                            </div>
                            <!-- Product actions-->
                            <div class="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                <div class="d-flex gap-2 justify-content-center py-5">
                                    <button @click="verDetallesArticulo(user)" class="btn btn-outline-dark mt-auto">Ver Artículo</button>
                                    <a class="btn btn-outline-dark mt-auto" @click="agregarAlCarrito(user)"><i class="bi-cart-fill me-1"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <!--Footer-->
        <div class="container">
        <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <div class="col-md-4 d-flex align-items-center">
            <a href="/" class="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
                <img src="../../assets/img/logo2.jpg" width="34" height="" class="rounded">
            </a>
            <span class="mb-3 mb-md-0 text-body-secondary">© 2024 Saldi</span>
            </div>

            <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li class="ms-3">
                <a class="text-body-secondary" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                </a>
            </li>
            <li class="ms-3">
                <a class="text-body-secondary" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                    </svg>
                </a>
            </li>
            <li class="ms-3">
                <a class="text-body-secondary" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                    </svg>
                </a>
            </li>
            </ul>
        </footer>
        </div>
        <!-- Agregar el componente CheckoutComponent -->
        <CartComponent :productos="carrito" @actualizar-carrito="actualizarCarrito" />
</template>
<script>
import CartComponent from '@/components/CartComponent.vue';
import AuthService from '@/services/AuthService.js';

export default {
    mounted() {

    this.updateMeta();
  },
  data() {
    return {
      userData: null,
      loading: true,
      carrito: JSON.parse(localStorage.getItem('carritosPorTienda'))?.[this.idTienda] || [],
      totalCarrito: (JSON.parse(localStorage.getItem('carritosPorTienda'))?.[this.idTienda] || []).length
    };
  },
  props: ['idTienda', 'nombreTienda', 'tituloTienda', 'subTituloTienda'],
  components: {
    CartComponent,
  },
  methods: {
    async agregarAlCarrito(producto) {
      this.carrito.push(producto);
      this.totalCarrito = this.carrito.length;
      this.actualizarLocalStorage();
      this.$emit('producto-agregado', producto);
    },
    actualizarCarrito(carritoActualizado) {
      this.carrito = carritoActualizado;
      this.totalCarrito = this.carrito.length;
      this.actualizarLocalStorage();
    },
    actualizarLocalStorage() {
      let carritosPorTienda = JSON.parse(localStorage.getItem('carritosPorTienda')) || {};
      carritosPorTienda[this.idTienda] = this.carrito;
      localStorage.setItem('carritosPorTienda', JSON.stringify(carritosPorTienda));
    },
    showCarrito() {
      console.log("carrito:", this.carrito);
    },
    verDetallesArticulo(user) {
      console.log("Detalle: ", user);
      this.$store.commit('guardarDetallesArticulo', user);
      this.$router.push({ name: 'ArticuloView', params: { tienda: this.nombreTienda, item: user.sku } });
    },
    updateMeta() {
    //  document.title = this.nombreTienda ? `${this.nombreTienda} - Shop With Style` : 'Saldi - Tu tienda en internet';

      const metaTags = [
        { name: 'classification', content: 'Venta de articulos' || 'Desarrollo de Software'},
        { name: 'publisher', content: 'Venta de articulos' || 'Desarrollo de Software, saldi.me'},
        { name: 'organization', content: 'saldi.me/bustedweb' || 'saldi.me'},
        { name: 'sitedomain', content: 'www.saldi.me/bustedweb' || 'www.saldi.me'},
        { name: 'description', content: 'Shop With Style' || 'Saldi es una plataforma fácil de usar que te permite crear tu tienda online gratuita y empezar a vender online.' },
        { property: 'og:title', content: 'BustedWeb' || 'Saldi - Tu tienda en internet' },
        { property: 'og:description', content: 'BustedWeb' || 'Saldi es una plataforma fácil de usar que te permite crear tu tienda online gratuita y empezar a vender online.' },
        { property: 'og:image', content: 'https://bustedweb.me/img/bustedweb.JPG' },
        { property: 'og:url', content: `https://saldi.me/${this.$route.params.tienda}` },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'BustedWeb' || 'Saldi - Tu tienda en internet' },
        { name: 'twitter:description', content: 'BustedWeb' || 'Saldi es una plataforma fácil de usar que te permite crear tu tienda online gratuita y empezar a vender online.' },
        { name: 'twitter:image', content: 'https://bustedweb.me/img/bustedweb.JPG' }
      ];
      

      const faviconUrl = 'https://raw.githubusercontent.com/ObedVega/broken-links/main/img/bw.jpg';

        // Agrega la etiqueta <link> para el favicon
        let faviconLink = document.querySelector('link[rel="icon"]');
        if (!faviconLink) {
            faviconLink = document.createElement('link');
            faviconLink.rel = 'icon';
            document.head.appendChild(faviconLink);
        }
        faviconLink.href = faviconUrl;

      metaTags.forEach(tag => {
        let metaTag = document.querySelector(`meta[${Object.keys(tag)[0]}="${Object.values(tag)[0]}"]`);
        if (!metaTag) {
          metaTag = document.createElement('meta');
          document.head.appendChild(metaTag);
        }
        metaTag.setAttribute(Object.keys(tag)[0], Object.values(tag)[0]);
        metaTag.setAttribute('content', tag.content);
      });
    }
  },
  async created() {
    document.title = this.nombreTienda ? `${this.nombreTienda} - Shop With Style` : 'Saldi - Tu tienda en internet';

    try {
      console.log("idTienda: ", this.idTienda);
      this.userData = await AuthService.getProductos(this.idTienda);
      console.log("userData:", this.userData);
      this.loading = false;
      this.updateMeta(); 
    } catch (error) {
      console.log('Error al obtener la información del usuario:', error);
    }
  }
}
</script>

<style scoped>
.fw-bolder {
    font-weight: bolder !important;
}
.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2;
}
.text-white {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
.text-center {
    text-align: center !important;
}
body {
    color: #fff;
}
</style>