<template>
    <section>
      <div class="container">
        <div class="row justify-content-center text-center mb-6">
          <div class="col-xl-8 col-lg-9">
            <h2 class="display-4 mx-xl-6">Construye tu propia tienda a tu medida..</h2>
            <p class="lead">
              Puedes elegir entre multiples diseños dependiendo el tipo de tienda o el tipo de productos que desees vender.
            </p>
          </div>
        </div>
        <div class="row justify-content-center mb-5">
          <div class="col-xl-11">
            <ul class="nav justify-content-center" role="tablist">
              <li class="nav-item mx-1">
                <a class="nav-link" @click="mostrarTab('tab1')" :class="{ active: tabActual === 'tab1' }" style="cursor: pointer;">
                    <div class="icon-round icon-round-sm bg-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary" data-src="assets/img/icons/theme/shopping/box-2.svg">
                      <title>Icon For Box#2</title>
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                          <path d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z" fill="#000000"></path>
                          <path d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z" fill="#000000" opacity="0.3"></path>
                      </g>
                  </svg>
                  </div>
                  Articulos
                </a>
              </li>
              <li class="nav-item mx-1">
                <a class="nav-link" @click="mostrarTab('tab2')" :class="{ active: tabActual === 'tab2' }" style="cursor: pointer;">
                    <div class="icon-round icon-round-sm bg-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary" data-src="assets/img/icons/theme/shopping/chart-pie.svg">
    <title>Icon For Chart-pie</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
        <path d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z" fill="#000000" opacity="0.3"></path>
        <path d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z" fill="#000000"></path>
    </g>
</svg>
                  </div>
                    Floreria</a>
              </li>
              <li class="nav-item mx-1">
                <a class="nav-link" @click="mostrarTab('tab3')" :class="{ active: tabActual === 'tab3' }" style="cursor: pointer;">
                    <div class="icon-round icon-round-sm bg-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary" data-src="assets/img/icons/theme/design/saturation.svg">
    <title>Icon For Saturation</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
        <path d="M7,14 C7,16.7614237 9.23857625,19 12,19 C14.7614237,19 17,16.7614237 17,14 C17,12.3742163 15.3702913,9.86852817 12,6.69922982 C8.62970872,9.86852817 7,12.3742163 7,14 Z M12,21 C8.13400675,21 5,17.8659932 5,14 C5,11.4226712 7.33333333,8.08933783 12,4 C16.6666667,8.08933783 19,11.4226712 19,14 C19,17.8659932 15.8659932,21 12,21 Z" fill="#000000" fill-rule="nonzero"></path>
        <path d="M12,4 C16.6666667,8.08933783 19,11.4226712 19,14 C19,17.8659932 15.8659932,21 12,21 L12,4 Z" fill="#000000"></path>
    </g>
</svg>
                  </div>Ropa</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-xl-11 aos-init aos-animate" data-aos="fade-up">
            <div class="tab-content">
              <div class="tab-pane fade" :class="{ 'show active': tabActual === 'tab1' }" id="saas-tab-1" role="tabpanel">
                <div class="popover-image">
                    <!--
                  <div class="popover-hotspot bg-primary-2" style="top: 25%; left: 10%;" data-toggle="popover" title="Hotspot title" data-content="And here's some amazing content. It's very engaging. Right?"></div>
                  <div class="popover-hotspot bg-primary-2" style="top: 10%; left: 90%;" data-toggle="popover" title="Hotspot title" data-content="And here's some amazing content. It's very engaging. Right?"></div>
                  <div class="popover-hotspot bg-primary-2" style="top: 90%; left: 20%;" data-toggle="popover" title="Hotspot title" data-content="And here's some more amazing content. It's very engaging. Right?"></div>
                  -->
                  <img src="../assets/img/store-1.jpg" alt="Image" class="rounded border shadow-lg">
                </div>
              </div>
              <div class="tab-pane fade" :class="{ 'show active': tabActual === 'tab2' }" id="saas-tab-2" role="tabpanel">
                <div class="popover-image">
                  <img src="../assets/img/store-2.png" alt="Image" class="rounded border shadow-lg">
                </div>
              </div>
              <div class="tab-pane fade" :class="{ 'show active': tabActual === 'tab3' }" id="saas-tab-3" role="tabpanel">
                <div class="popover-image">
                    <!--
                  <div class="popover-hotspot bg-primary-2" style="top: 2%; left: 2%;" data-toggle="popover" title="Hotspot title" data-content="And here's some amazing content. It's very engaging. Right?"></div>
                  <div class="popover-hotspot bg-primary-2" style="top: 35%; left: 95%;" data-toggle="popover" title="Hotspot title" data-content="And here's some amazing content. It's very engaging. Right?"></div>
                  <div class="popover-hotspot bg-primary-2" style="top: 90%; left: 50%;" data-toggle="popover" title="Hotspot title" data-content="And here's some more amazing content. It's very engaging. Right?"></div>
                  -->
                  <img src="../assets/img/store-3.png" alt="Image" class="rounded border shadow-lg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        tabActual: 'tab1' // Inicialmente mostramos la pestaña 1
      };
    },
    methods: {
      mostrarTab(tab) {
        this.tabActual = tab;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Aquí puedes agregar estilos específicos para esta componente si los necesitas */
  </style>
  