<template>
    <component :is="selectedComponent" />
</template>
<script>
import TemaCeroComponent from '@/components/Temas/Demos/TemaCero/TemaCeroComponent.vue';
import TemaUnoComponent from '@/components/Temas/Demos/TemaUno/TemaUnoComponent.vue';
export default {
    components: {
        TemaCeroComponent,
        TemaUnoComponent
    },
    data() {
        return {
            selectedComponent: null,
            tema: '',
        }
    },
    mounted() {     
        this.tema = this.$route.params.demo;
        this.getTemplateDemo();
    },
    methods: {
        async getTemplateDemo() {
            console.log('Tienda Demo: ', this.tema);
            switch (this.tema) {
            case 'default':
                this.selectedComponent = TemaCeroComponent;
                break;
            case 'muebleria':
                this.selectedComponent = TemaUnoComponent;
                break;

            default:
            //    this.notFound = NotFound;
            //    console.log('Tienda no encontrada:', this.store);
                break;
            }
        }
    }
}
</script>