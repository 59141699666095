<template>
    <div class="alert alert-warning alert-dismissible fade show sticky-banner" role="alert">
        <strong>Hola!!! Gracias por visitarnos!</strong> Este es un diseño de como se veria tu tienda gratuita!!  Que esperas? 
        Registrate <a href="https://admin.saldi.me/register" target="_blank">aqui!!</a> y comienza a vender en linea tus productos.
        <strong> Este demo no ofrece ninguna funcionalidad.</strong>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container px-4 px-lg-5">
                <a class="navbar-brand" href="https://saldi-shop.github.io/web/shop">Mi Tienda Demo</a>
                <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                <div class="navbar-collapse collapse" id="navbarSupportedContent" style="">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-4">
                        <li class="nav-item"><a class="nav-link active" aria-current="page" href="https://saldi-shop.github.io/web/shop/">Inicio</a></li>
                     <!--   <li class="nav-item"><a class="nav-link" href="#!">Conocenos</a></li> -->
                        <!-- 
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Shop</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="#!">All Products</a></li>
                                <li><hr class="dropdown-divider" /></li>
                                <li><a class="dropdown-item" href="#!">Popular Items</a></li>
                                <li><a class="dropdown-item" href="#!">New Arrivals</a></li>
                            </ul>
                        </li>
                        -->
                    </ul>
                    <form class="d-flex">
                        <button class="btn btn-outline-dark" type="submit">
                            <i class="bi-cart-fill me-1"></i>
                            Carrito
                            <span class="badge bg-dark text-white ms-1 rounded-pill">0</span>
                        </button>
                    </form>
                </div>
            </div>
        </nav>
        <header class="bg-dark py-5">
            <div class="container px-4 px-lg-5 my-5">
                <div class="text-center text-white">
                    <h1 class="display-4 fw-bolder">Saldi</h1>
                    <p class="lead fw-normal text-white-50 mb-0">El mejor lugar para vender!</p>
                </div>
            </div>
        </header>
        <section class="py-5">
            <div class="container px-4 px-lg-5 mt-5">
                <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
                    <div class="col mb-5">
                        <div class="card h-100">
                            <!-- Product image-->
                            <img class="card-img-top" src="../../../../assets/img/sueter.jpeg" alt="...">
                            <!-- Product details-->
                            <div class="card-body p-4">
                                <div class="text-center">
                                    <!-- Product name-->
                                    <h5 class="fw-bolder">Bustedweb sweater </h5>
                                    <!-- Product price-->
                                    $199.99
                                </div>
                            </div>
                            <!-- Product actions-->
                            <div class="card-footer p-4 pt-0 border-top-0 bg-transparent d-flex gap-2">
                                <div class="text-left"><a class="btn btn-outline-dark mt-auto" href="https://saldi-shop.github.io/web/shop/54861/">Ver articulo</a></div>
                                <div class="justify-content-md-end"><a class="btn btn-outline-dark mt-auto" href="#"><i class="bi-cart-fill me-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-5">
                        <div class="card h-100">
                            <!-- Sale badge-->
                            <div class="badge bg-dark text-white position-absolute" style="top: 0.5rem; right: 0.5rem">Oferta</div>
                            <!-- Product image-->
                            <img class="card-img-top" src="../../../../assets/img/shirt.jpeg" alt="...">
                            <!-- Product details-->
                            <div class="card-body p-4">
                                <div class="text-center">
                                    <!-- Product name-->
                                    <h5 class="fw-bolder">Playera</h5>
                                    <!-- Product reviews-->
                                    <div class="d-flex justify-content-center small text-warning mb-2">
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                    </div>
                                    <!-- Product price-->
                                    <span class="text-muted text-decoration-line-through">$200.00</span>
                                    $150.00
                                </div>
                            </div>
                            <!-- Product actions-->
                            <div class="card-footer p-4 pt-0 border-top-0 bg-transparent d-flex gap-2">
                                <div class="text-left"><a class="btn btn-outline-dark mt-auto" href="https://saldi-shop.github.io/web/shop/54861/">Ver articulo</a></div>
                                <div class="justify-content-md-end"><a class="btn btn-outline-dark mt-auto" href="#"><i class="bi-cart-fill me-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-5">
                        <div class="card h-100">
                            <!-- Oferta badge-->
                            <div class="badge bg-dark text-white position-absolute" style="top: 0.5rem; right: 0.5rem">Oferta</div>
                            <!-- Product image-->
                            <img class="card-img-top" src="../../../../assets/img/orange-cup.jpeg" alt="...">
                            <!-- Product details-->
                            <div class="card-body p-4">
                                <div class="text-center">
                                    <!-- Product name-->
                                    <h5 class="fw-bolder">Taza para cafe</h5>
                                    <!-- Product price-->
                                    <span class="text-muted text-decoration-line-through">$75.00</span>
                                    $50.00
                                </div>
                            </div>
                            <!-- Product actions-->
                            <div class="card-footer p-4 pt-0 border-top-0 bg-transparent d-flex gap-2">
                                <div class="text-left"><a class="btn btn-outline-dark mt-auto" href="https://saldi-shop.github.io/web/shop/54861/">Ver articulo</a></div>
                                <div class="justify-content-md-end"><a class="btn btn-outline-dark mt-auto" href="#"><i class="bi-cart-fill me-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-5">
                        <div class="card h-100">
                            <!-- Product image-->
                            <img class="card-img-top" src="../../../../assets/img/blue-cup.jpeg" alt="...">
                            <!-- Product details-->
                            <div class="card-body p-4">
                                <div class="text-center">
                                    <!-- Product name-->
                                    <h5 class="fw-bolder">Taza para cafe</h5>
                                    <!-- Product reviews-->
                                    <div class="d-flex justify-content-center small text-warning mb-2">
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                    </div>
                                    <!-- Product price-->
                                    $75.00
                                </div>
                            </div>
                            <!-- Product actions-->
                            <div class="card-footer p-4 pt-0 border-top-0 bg-transparent d-flex gap-2">
                                <div class="text-left"><a class="btn btn-outline-dark mt-auto" href="https://saldi-shop.github.io/web/shop/54861/">Ver articulo</a></div>
                                <div class="justify-content-md-end"><a class="btn btn-outline-dark mt-auto" href="#"><i class="bi-cart-fill me-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-5">
                        <div class="card h-100">
                            <!-- Sale badge-->
                            <div class="badge bg-dark text-white position-absolute" style="top: 0.5rem; right: 0.5rem">Oferta</div>
                            <!-- Product image-->
                            <img class="card-img-top" src="../../../../assets/img/shirt.jpeg" alt="...">
                            <!-- Product details-->
                            <div class="card-body p-4">
                                <div class="text-center">
                                    <!-- Product name-->
                                    <h5 class="fw-bolder">Playera</h5>
                                    <!-- Product price-->
                                    <span class="text-muted text-decoration-line-through">$199.99</span>
                                    $150.00
                                </div>
                            </div>
                            <!-- Product actions-->
                            <div class="card-footer p-4 pt-0 border-top-0 bg-transparent d-flex gap-2">
                                <div class="text-left"><a class="btn btn-outline-dark mt-auto" href="https://saldi-shop.github.io/web/shop/54861/">Ver articulo</a></div>
                                <div class="justify-content-md-end"><a class="btn btn-outline-dark mt-auto" href="#"><i class="bi-cart-fill me-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-5">
                        <div class="card h-100">
                            <!-- Product image-->
                            <img class="card-img-top" src="../../../../assets/img/sueter.jpeg" alt="...">
                            <!-- Product details-->
                            <div class="card-body p-4">
                                <div class="text-center">
                                    <!-- Product name-->
                                    <h5 class="fw-bolder">Bustedweb sweater</h5>
                                    <!-- Product price-->
                                    $199.99
                                </div>
                            </div>
                            <!-- Product actions-->
                            <div class="card-footer p-4 pt-0 border-top-0 bg-transparent d-flex gap-2">
                                <div class="text-left"><a class="btn btn-outline-dark mt-auto" href="https://saldi-shop.github.io/web/shop/54861/">Ver articulo</a></div>
                                <div class="justify-content-md-end"><a class="btn btn-outline-dark mt-auto" href="#"><i class="bi-cart-fill me-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-5">
                        <div class="card h-100">
                            <!-- Sale badge-->
                            <div class="badge bg-dark text-white position-absolute" style="top: 0.5rem; right: 0.5rem">Oferta</div>
                            <!-- Product image-->
                            <img class="card-img-top" src="../../../../assets/img/blue-cup.jpeg" alt="...">
                            <!-- Product details-->
                            <div class="card-body p-4">
                                <div class="text-center">
                                    <!-- Product name-->
                                    <h5 class="fw-bolder">Taza para cafe</h5>
                                    <!-- Product reviews-->
                                    <div class="d-flex justify-content-center small text-warning mb-2">
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                    </div>
                                    <!-- Product price-->
                                    <span class="text-muted text-decoration-line-through">$75.00</span>
                                    $50.00
                                </div>
                            </div>
                            <!-- Product actions-->
                            <div class="card-footer p-4 pt-0 border-top-0 bg-transparent d-flex gap-2">
                                <div class="text-left"><a class="btn btn-outline-dark mt-auto" href="https://saldi-shop.github.io/web/shop/54861/">Ver articulo</a></div>
                                <div class="justify-content-md-end"><a class="btn btn-outline-dark mt-auto" href="#"><i class="bi-cart-fill me-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-5">
                        <div class="card h-100">
                            <!-- Product image-->
                            <img class="card-img-top" src="../../../../assets/img/orange-cup.jpeg" alt="...">
                            <!-- Product details-->
                            <div class="card-body p-4">
                                <div class="text-center">
                                    <!-- Product name-->
                                    <h5 class="fw-bolder">Taza para cafe</h5>
                                    <!-- Product reviews-->
                                    <div class="d-flex justify-content-center small text-warning mb-2">
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                        <div class="bi-star-fill"></div>
                                    </div>
                                    <!-- Product price-->
                                    $50.00
                                </div>
                            </div>
                            <!-- Product actions-->
                            <div class="card-footer p-4 pt-0 border-top-0 bg-transparent d-flex gap-2">
                                <div class="text-left"><a class="btn btn-outline-dark mt-auto" href="https://saldi-shop.github.io/web/shop/54861/">Ver articulo</a></div>
                                <div class="justify-content-md-end"><a class="btn btn-outline-dark mt-auto" href="#"><i class="bi-cart-fill me-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer class="py-5 bg-dark">
            <div class="container"><p class="m-0 text-center text-white">Copyright © <a href="/">saldi.mx</a> 2024</p></div>
        </footer>
</template>
<style scoped>

    @import 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css';      
    @import '../../../../assets/css/styles.css';
    
    .sticky-banner {
    position: -webkit-sticky; /* Soporte para Safari */
    position: sticky;
    top: 0;
    z-index: 1030; /* Asegura que el banner esté sobre otros elementos */
    width: 100%; /* Asegura que el banner ocupe todo el ancho */
}
</style>