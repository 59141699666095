import axios from 'axios';

const baseURL = 'https://mywedding-backend.onrender.com'; 
//const serverimagenes = 'https://img-server-zxm5.onrender.com';

export default {
    async getInfoTienda(tienda_id) {
        try {
            const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
            
            const response = await axios.get(`${baseURL}/tienda/${tienda_id}`, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer Toekn`,
                    'Accept': 'application/json'
                }
            });
    
            if (response.status === 404) {
                // Si la respuesta es vacía (sin contenido), devuelve un objeto JSON vacío
                console.log("404 no se encontro tienda")
                return {};
            } else {
                // Si la respuesta contiene datos, devuelve los datos de la respuesta
                return response.data;
            }
        } catch (error) {
            //console.error('Error al obtener la información del usuario:', error);
            // En lugar de lanzar una excepción, simplemente devuelve un objeto JSON vacío
            return {};
        }
    },
      async getProductos(id) {
        try {
            const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
              
            const response = await axios.get(`${baseURL}/producto/${id}`, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer X`,
                    'Accept': 'application/json'
                }
            });  
            console.log(response);
            // Verificar si la respuesta tiene datos
            if (response && response.data) {
                console.log(response.data);
                return response.data;
            }
            if (response.status === 404){
                // El recurso no fue encontrado, puedes manejar este caso de acuerdo a tus necesidades
                console.log('El producto no fue encontrado con el id: ', id);
                return null; // O puedes devolver un objeto vacío, dependiendo de tu lógica
            }
        } catch (error) {
            // Manejar el error adecuadamente
            console.error('Error al obtener la información del usuario:', error);
            throw new Error('Error al obtener la información del usuario:', error);
        }
    },
    async getItem(tienda, item) {
        console.log(tienda, item);
        try {
            const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
              
            const response = await axios.get(`${baseURL}/item/${tienda}/${item}`, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer X`,
                    'Accept': 'application/json'
                }
            });  
            console.log(response);
            // Verificar si la respuesta tiene datos
            if (response && response.data) {
                console.log(response.data);
                return response.data;
            }
            if (response.status === 404){
                // El recurso no fue encontrado, puedes manejar este caso de acuerdo a tus necesidades
                console.log('El producto no fue encontrado con el id: ', item);
                return null; // O puedes devolver un objeto vacío, dependiendo de tu lógica
            }
        } catch (error) {
            // Manejar el error adecuadamente
            console.error('Error al obtener la información del usuario:', error);
            throw new Error('Error al obtener la información del usuario:', error);
        }
    },
    async generaOrdenDeCompra(nombreTienda) {
        console.log("nombreTienda:",nombreTienda);
        try{
            const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
            const headers = {
                'X-CSRFToken': csrfToken,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + 'accessToken',
                'Accept': 'application/json'
            };
    
            return axios.post(`${baseURL}/ordenes/`, { nombreTienda }, { headers });
        }catch(e){
          console.log('Error:', e);
          throw e; 
        }
    },
    async metodosDePago(tienda){
        console.log("nombreTienda:",tienda);
        try{
            const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
            const response = await axios.get(`${baseURL}/webPagos/${tienda}`, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer Toekn`,
                    'Accept': 'application/json'
                }
            });
    
            if (response.status === 404) {
                // Si la respuesta es vacía (sin contenido), devuelve un objeto JSON vacío
                console.log("404 no se encontro tienda")
                return {};
            } else {
                // Si la respuesta contiene datos, devuelve los datos de la respuesta
                return response.data;
            }
        }catch(e){
          console.log('Error:', e);
          throw e; 
        }
    }
};