<template>
    <section class="has-divider">
      <div class="container">
        <div class="row align-items-center justify-content-between o-hidden">
          <div class="col-md-6 order-sm-2 mb-5 mb-sm-0 aos-init aos-animate" data-aos="fade-left">
            <img src="../assets/img/saas-3.svg" alt="Image">
          </div>
          <div class="col-md-6 pr-xl-5 order-sm-1">
            <h1 class="display-4">Vende con Saldi</h1>
            <p class="lead">Tu plataforma para crear una tienda en línea, ¡totalmente gratis!.</p>
            <div class="d-sm-flex mb-2 mt-4">
              <a class="btn btn-lg btn-outline-primary" href="/demo/default" target="_blank">Ver Demo</a>
              &nbsp; &nbsp;
              <a class="btn btn-lg btn-primary" type="submit" href="https://admin.saldi.me/register" target="_blank">Comenzar prueba</a>
            </div>
            <span class="text-small text-muted">Ya estas utilizando Saldi? <a href="https://admin.saldi.me/">Inicio Sesion</a>
            </span>
          </div>
        </div>
      </div>
      <div class="divider">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="96px" viewBox="0 0 100 100" version="1.1" preserveAspectRatio="none" class="injected-svg bg-primary-alt" data-src="assets/img/dividers/divider-1.svg">
            <path d="M0,0 C40,33 66,52 75,52 C83,52 92,33 100,0 L100,100 L0,100 L0,0 Z"></path>
        </svg>
      </div>
    </section>
</template>
<style scoped>
.btn-group-lg>.btn, .btn-lg {
    padding: .875rem 2.25rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3125rem;
}
.btn-primary {
    color: #fff;
    background-color: #0f66dc;
    border-color: #0f66dc;
}
.btn-outline-primary {
    color: #0f66dc;
    border-color: #0f66dc;
}
.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #0f66dc;
    border-color: #0f66dc;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0d55b8;
    border-color: #0c50ac;
}
</style>