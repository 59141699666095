<template>
  <div id="paypal-button-container"></div>
</template>

<script>
export default {
  props: ['total', 'disabled', 'paypalUser'],
  watch: {
    disabled(newVal) {
      if (this.paypalButtons) {
        this.paypalButtons.setProps({ disabled: newVal });
      }
    }
  },
  mounted() {
    console.log(this.total);
    const vm = this;
  //  const merchant = 'obed-test@business.example.com'
    console.log(this.paypalUser);
    // Crear el script para cargar el SDK de PayPal
    const script = document.createElement('script');
//    script.src = `https://www.paypal.com/sdk/js?client-id=AaMFjCttrUUbnCIGu5iKflmWKmp7qth0u1wVZsyELS99xgKkFFi65DPHN9UsuYQHRFqSn0NA5eNy0Kqa&merchant-id=${merchant}&currency=MXN&components=buttons`;
  script.src = `https://www.paypal.com/sdk/js?client-id=AaMFjCttrUUbnCIGu5iKflmWKmp7qth0u1wVZsyELS99xgKkFFi65DPHN9UsuYQHRFqSn0NA5eNy0Kqa&currency=MXN&components=buttons`;
   
    script.onload = () => {
      // Renderizar los botones de PayPal cuando el SDK se ha cargado
      // eslint-disable-next-line no-undef
      paypal.Buttons({
        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: vm.total // Aquí especificas el monto a pagar 
              },
              payee: {
                email_address: vm.paypalUser // Aquí el email del destinatario
              }
            }]
          });
        },
        onApprove: function(data, actions) {
          return actions.order.capture().then(function(details) {
            // Aquí puedes acceder al transaction id
            const transactionId = details.id;
            console.log('Articulo Pagado por: ' + details.payer.name.given_name);
            console.log('Transaction ID: ' + transactionId);

            // Puedes realizar alguna acción adicional, como enviar el ID a tu servidor
             vm.$emit('transaction-success', transactionId);
          });
        },
        style: {
          layout: 'vertical',
          color: 'blue',
          shape: 'rect',
          label: 'paypal'
        },
        locale: 'es_ES'
      }).render('#paypal-button-container');
    };

    // Agregar el script al DOM
    document.head.appendChild(script);
  }
};
</script>

<style scoped>
/* Estilos específicos del componente, si es necesario */
</style>
