<template>
                <div class="col-md-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
              <div class="card card-body align-items-center ">
                <div class="pt-md-2">
                  <h4>Premium</h4>
                </div>
                <div class="d-flex align-items-start pb-md-2">
                  <span class="h3">$</span>
                  <span class="display-4 mb-0">199</span>
                </div>
                <span class="text-small text-muted">al mes</span>
                <ul class="text-center list-unstyled my-2 my-md-4">
                    <li class="py-1">
                        <span>10 Tiendas en linea</span>
                      </li>
                      <li class="py-1">
                        <span>Usuarios Ilimitados</span>
                      </li>
                      <li class="py-1">
                        <span>Productos Ilimitados</span>
                      </li>
                      <li class="py-1">
                        <span>Temas Ilimitados</span>
                      </li>
                      <li class="py-1">
                        <span class="">Soporte tecnico</span>
                      </li>
                      <li class="py-1">
                        <span class="">Pagina de inicio</span>
                      </li>
                      <li class="py-1">
                        <span class="">Saldi PickUp</span>
                      </li>
                      <li class="py-1">
                        <span class="">Saldi Delivery</span>
                      </li>
                      <li class="py-1">
                        <span>Dominio personalizado</span>
                      </li>
                </ul>
  
                <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" data-bs-title="Disabled tooltip">
                  <button class="btn btn-outline-primary" type="button" disabled>Obten el plan Premiumn</button>
                </span>

                
              </div>
            </div>
</template>