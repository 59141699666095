<template>
    <Navbar/>
    <!---->
    <section class="pt-0" data-reading-position="">
      <div class="container">
        <div class="row">
          <div class="col-auto">
          </div>
          <div class="col">
            <div class="row justify-content-center">
              <div class="col-xl-7 col-lg-8">
                <span class="text-muted">Enero 2024</span>
                <h1 class="my-2">Términos y Condiciones de Uso</h1>
                <hr>
                <article class="article">
                  <p class="lead">
                    Bienvenido a Saldi. Al acceder y utilizar nuestros servicios, usted acepta estar legalmente vinculado por los siguientes términos y condiciones. Por favor, lea detenidamente esta sección antes de utilizar nuestros servicios.
                  </p>
                  <h4>1. Uso del Servicio</h4>
                  <ul>
                    <li>1.1 Al acceder y utilizar nuestros servicios, usted acepta cumplir con estos términos y condiciones y con todas las leyes y regulaciones aplicables.</li>
                    <li>1.2 Usted es responsable de mantener la confidencialidad de su cuenta y contraseña y de restringir el acceso a su cuenta. Usted acepta asumir la responsabilidad de todas las actividades que ocurran bajo su cuenta o contraseña.</li>
                    <li>1.3 Usted acepta no utilizar nuestros servicios con fines ilegales o no autorizados.</li>
                  </ul>

                  <h4>2. Privacidad</h4>
                  <p>
                    Utilizamos la información recopilada para los siguientes propósitos:
                  </p>
                  <ul>
                    <li>2.1 Nos tomamos la privacidad de nuestros usuarios en serio. Por favor, consulte nuestra <a href="/privacy">política de privacidad</a> para obtener más información sobre cómo recopilamos, utilizamos y protegemos la información personal.</li>
                  </ul>

                  <h4>3. Contenido del Usuario</h4>
                  <ul>
                    <li>3.1 Usted es responsable de todo el contenido que publique o comparta a través de nuestros servicios, incluyendo texto, imágenes, videos y otros materiales ("Contenido del Usuario").</li>
                    <li>3.2 Usted garantiza que tiene todos los derechos necesarios para publicar o compartir el Contenido del Usuario y que dicho contenido no infringe los derechos de terceros.</li>
                    <li>3.3 Nos reservamos el derecho, a nuestra sola discreción, de eliminar o modificar cualquier Contenido del Usuario que consideremos inapropiado o que viole estos términos y condiciones.</li>
                  </ul>

                  <h4>4. Propiedad Intelectual</h4>
                  <ul>
                    <li>4.1 Todos los derechos de propiedad intelectual sobre nuestros servicios y cualquier contenido relacionado, incluyendo pero no limitado a textos, gráficos, logotipos, iconos, imágenes, clips de audio y video, pertenecen a Saldi o a sus licenciantes.</li>
                    <li>4.2 Usted acepta no copiar, modificar, distribuir, transmitir, exhibir, reproducir, publicar, licenciar, crear trabajos derivados, transferir o vender cualquier información, software, productos o servicios obtenidos de nuestros servicios.</li>
                  </ul>

                  <h4>5. Limitación de Responsabilidad</h4>
                  <ul>
                    <li>5.1 En la máxima medida permitida por la ley, Saldi no será responsable ante usted por daños directos, indirectos, incidentales, especiales, consecuentes o punitivos que surjan de su acceso o uso de nuestros servicios.</li>
                    <li>5.2 Usted acepta que su uso de nuestros servicios es bajo su propio riesgo y que nuestros servicios se proporcionan "tal cual" y "según disponibilidad", sin garantías de ningún tipo, ya sean expresas o implícitas.</li>
                  </ul>
                  <h4>6. Modificaciones</h4>
                  <ul>
                    <li>6.1 Nos reservamos el derecho, a nuestra sola discreción, de modificar o revisar estos términos y condiciones en cualquier momento sin previo aviso. El uso continuado de nuestros servicios después de cualquier modificación constituirá su aceptación de tales cambios.</li>
                  </ul>
                  <h4>7. Ley Aplicable</h4>
                  <ul>
                    <li>7.1 Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes del Estado de California, sin tener en cuenta sus conflictos de principios legales.</li>
                  </ul>
                </article>
                <hr>
                <h6>Importante</h6>
                <small>Al utilizar nuestros servicios, usted acepta cumplir con estos términos y condiciones. Si no está de acuerdo con alguno de los términos establecidos aquí, le rogamos que no utilice nuestros servicios.</small>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!---->
    <FooterComponent />
</template>
<script>
import Navbar from '@/components/NavBar.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
    components: {
        Navbar,
        FooterComponent,
    }
}
</script>
<style>

</style>