<template>
  <div class="container">
    <main>
      <div class="py-5 text-center">
        <h2>Forma para pagar</h2>
        <p class="lead">{{ store }}</p>
      </div>

      <div class="row g-5">
        <div class="col-md-5 col-lg-4 order-md-last">
          <h4 class="d-flex justify-content-between align-items-center mb-3">
            <span class="text-primary">Tu carrito de compras</span>
            <span class="badge bg-primary rounded-pill">{{productosCarrito.length}}</span>
          </h4>
          <ul class="list-group mb-3">
            <li v-for="(producto, index) in productosCarrito" :key="index" class="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 class="my-0">{{ producto.producto }}</h6>
                <small class="text-body-secondary">Brief description</small>
              </div>
              <span class="text-body-secondary">$ {{ producto.precio }} </span>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <span>Total (MX)</span>
              <strong>${{ calcularTotal() }}</strong>
            </li>
          </ul>
          <div class="card p-2">
            <a :href="`/${store}`" class="w-100 btn btn-primary btn-lg">Continuar comprando</a>
          </div>
        </div>

        <div class="col-md-7 col-lg-8">
          <h4 class="mb-3">Informacion de envio</h4>

            <div class="row g-3">
              <div class="col-sm-6">
                <label for="firstName" class="form-label">Nombre <span class="text-body-secondary">*</span></label>
                <input type="text" class="form-control" id="firstName" v-model="formData.firstName" required>
                <div v-if="showValidationMessage && !formData.firstName" class="invalid-feedback">
                  Se requiere un nombre válido.
                </div>
              </div>
              <div class="col-sm-6">
                <label for="lastName" class="form-label">Apellido <span class="text-body-secondary">*</span></label>
                <input type="text" class="form-control" id="lastName" v-model="formData.lastName" required>
                <div v-if="showValidationMessage && !formData.lastName" class="invalid-feedback">
                  Se requiere un apellido válido.
                </div>
              </div>
              <div class="col-12">
                <label for="email" class="form-label">Correo Electronico <span class="text-body-secondary">*</span></label>
                <input type="email" class="form-control" id="email" v-model="formData.email" required>
                <div v-if="showValidationMessage && !formData.email" class="invalid-feedback">
                  Ingrese una dirección de correo electrónico válida para recibir actualizaciones de envío.
                </div>
              </div>
              <div class="col-12">
                <label for="address" class="form-label">Direccion <span class="text-body-secondary">*</span></label>
                <input type="text" class="form-control" id="address" v-model="formData.address" required>
                <div v-if="showValidationMessage && !formData.address" class="invalid-feedback">
                  Por favor introduzca su direccion de envio.
                </div>
              </div>
              <div class="col-12">
                <label for="address2" class="form-label">Detalles adicionales <span class="text-body-secondary">(Opcional)</span></label>
                <textarea class="form-control" id="address2" v-model="formData.address2" rows="4" cols="50">
                  Detalles adicionales para tu envio.
                </textarea>
              </div>
              <div class="col-md-5">
                <label for="state" class="form-label">Estado</label>
                <select class="form-select" id="state" v-model="formData.state" required>
                  <option value="">Seleccionar...</option>
                  <option>Aguascalientes</option>
                <option>Baja California</option>
                <option>Baja California Sur</option>
                <option>Campeche</option>
                <option>Chiapas</option>
                <option>Chihuahua</option>
                <option>Ciudad de México</option>
                <option>Coahuila</option>
                <option>Colima</option>
                <option>Durango</option>
                <option>Estado de México</option>
                <option>Guanajuato</option>
                <option>Guerrero</option>
                <option>Hidalgo</option>
                <option>Jalisco</option>
                <option>Michoacán</option>
                <option>Morelos</option>
                <option>Nayarit</option>
                <option>Nuevo León</option>
                <option>Oaxaca</option>
                <option>Puebla</option>
                <option>Querétaro</option>
                <option>Quintana Roo</option>
                <option>San Luis Potosí</option>
                <option>Sinaloa</option>
                <option>Sonora</option>
                <option>Tabasco</option>
                <option>Tamaulipas</option>
                <option>Tlaxcala</option>
                <option>Veracruz</option>
                <option>Yucatán</option>
                <option>Zacatecas</option>
                </select>
                <div v-if="showValidationMessage && !formData.state" class="invalid-feedback">
                  Proporcione un estado válido.
                </div>
              </div>
              <div class="col-md-4">
                <label for="ciudad" class="form-label">Ciudad</label>
                <input type="text" class="form-control" id="ciudad" v-model="formData.ciudad">
                <div v-if="showValidationMessage && !formData.ciudad" class="invalid-feedback">
                  Proporcione una ciudad válida.
                </div>
              </div>
              <div class="col-md-3">
                <label for="zip" class="form-label">CP</label>
                <input type="text" class="form-control" id="zip" v-model="formData.zip" required>
                <div v-if="showValidationMessage && !formData.zip" class="invalid-feedback">
                  Se requiere codigo postal.
                </div>
              </div>
            </div>

            <hr class="my-4">
            <h4 class="mb-3">Seleccionar metodo de pago</h4>
            
            <div class="my-3">      
<!------>
<div v-if="metodosFiltrados.length">
              <div v-for="metodo in metodosFiltrados" :key="metodo.metodo" class="form-check">
                <input 
                  :name="`paymentMethod${metodo.metodo}`" 
                  type="radio" 
                  class="form-check-input" 
                  v-model="formData.paymentMethod" 
                  :value="metodo.metodo.toLowerCase()" 
                  @change="handlePaymentMethodChange"
                >
                <label class="form-check-label" :for="metodo.metodo.toLowerCase()">{{ metodo.metodo }}</label>
              </div>
            </div>
              <!---->
  
              <!---->
                <div class="form-check">
                <input 
                  name="paymentMethodPayPal" 
                  type="radio" 
                  class="form-check-input" 
                  v-model="formData.paymentMethod" 
                  value="PayPal" 
                  @change="handlePaymentMethodChange"
                >
                <label class="form-check-label" for="PayPal">PayPal</label>
              </div>
              <div class="form-check">
                <input 
                  name="paymentMethodClip" 
                  type="radio" 
                  class="form-check-input" 
                  v-model="formData.paymentMethod" 
                  value="clip" 
                  @change="handlePaymentMethodChange"
                >
                <label class="form-check-label" for="clip">Clip</label>
              </div>
              <div class="form-check">
                <input 
                  name="paymentMethodPae" 
                  type="radio" 
                  class="form-check-input" 
                  v-model="formData.paymentMethod" 
                  value="pae" 
                  @change="handlePaymentMethodChange"
                >
                <label class="form-check-label" for="pae">Pago Al Entregar</label>
              </div>
              <div v-if="showValidationMessage" class="text-danger mt-2">Por favor, completa todos los campos obligatorios.</div>
              <div class="form-check">
                <input id="credito" name="paymentMethod" type="radio" class="form-check-input"  value="credito" disabled>
                <label class="form-check-label" for="debcreditoit">Tarjeta de credito</label>
              </div>
              <div class="form-check">
                <input id="oxxo" name="paymentMethod" type="radio" class="form-check-input" value="oxxo" disabled>
                <label class="form-check-label" for="oxxo">Pago en Oxxo</label>
              </div>
            </div>
            <div class="paypal-button-container">
              <PayPalButtons 
                v-show="showPayPalButtons"
                :total="calcularTotal()" 
                :paypalUser="paypalUser"
                @transaction-success="handleTransactionSuccess"
              />
              <ClipButton v-show="showClipButton" />
              <button v-show="showPaeButton" class="w-100 btn btn-primary btn-lg" type="submit">Pagar Al Entregar</button>
            </div>
        </div>
      </div>
    </main>

    <footer class="my-5 pt-5 text-body-secondary text-center text-small">
      <p class="mb-1">© 2017–2024 Company Name</p>
      <ul class="list-inline">
        <li class="list-inline-item"><a href="#">Privacy</a></li>
        <li class="list-inline-item"><a href="#">Terms</a></li>
        <li class="list-inline-item"><a href="#">Support</a></li>
      </ul>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AuthService from '@/services/AuthService.js';
import PayPalButtons from './BotonesDePago/PayPalButtons.vue';
import ClipButton from './BotonesDePago/ClipButton.vue';

export default {
  data() {
    return {
      userData: null,
      store: '', 
      isValidExpirationDate: true,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        address2: '',
        state: '',
        ciudad: '',
        zip: '',
        paymentMethod: '', 
      },
      isFormValid: false,
      showValidationMessage: false,
      showPayPalButtons: false,
      showClipButton: false,
      showPaeButton: false,
      paypalUser: '',
    //  metodosDePago: [],
      metodosFiltrados: []
    };
  },
  computed: {
    ...mapState(['productosCarrito'])
  },
  methods: {
    calcularTotal() {
      return this.productosCarrito.reduce((total, producto) => total + parseFloat(producto.precio), 0).toFixed(2);
    },
    async realizarPago() {
      console.log('Pago Realizado!! Generar orden');

      console.log('Datos del formulario:', this.formData);
        try {
          this.userData = await AuthService.generaOrdenDeCompra(this.store);
          console.log("Respuesta completa de generaOrdenDeCompra:", this.userData);

          if (this.userData && this.userData.data.orden) {
            console.log("Número de orden generado:", this.userData.data.orden);

              // Redireccionar a la página de éxito
              this.$router.push(`/success/${this.userData.data.orden}`);
          } else {
              console.error("La respuesta no contiene el campo 'orden'");
          }
        } catch (error) {
          console.error("Error al generar la orden de compra:", error);
        }
    },
    handleTransactionSuccess(transactionId) {
      console.log('Transaction ID received in parent component:', transactionId);
      try {
        // Aquí puedes realizar acciones adicionales según sea necesario, como enviar datos al servidor
        this.realizarPago();
      } catch (error) {
        console.error('Error during handleTransactionSuccess:', error);
        // Manejo de errores específico, por ejemplo, mostrar un mensaje al usuario
      }
    },
    validateForm() {
      this.isFormValid = this.formData.firstName && this.formData.lastName && this.formData.email && this.formData.address && this.formData.state && this.formData.ciudad && this.formData.zip;
    },
    handlePaymentMethodChange() {
        this.validateForm();
        if (!this.isFormValid) {
          this.showValidationMessage = true;
          this.formData.paymentMethod = ''; // Resetea el método de pago si el formulario no es válido
          this.showPayPalButtons = false;
          this.showClipButton = false;
          this.showPaeButton = false; // Agregar para el nuevo método de pago 'pae'
        } else {
          this.showValidationMessage = false;
          if (this.formData.paymentMethod === 'PayPal') {
            this.showPayPalButtons = true; // Muestra los botones de PayPal si el método de pago es PayPal
            this.showClipButton = false;
            this.showPaeButton = false; // Asegurarse de que 'pae' está oculto
          } else if (this.formData.paymentMethod === 'clip') {
            this.showPayPalButtons = false;
            this.showClipButton = true; // Muestra los botones de Clip si el método de pago es Clip
            this.showPaeButton = false; // Asegurarse de que 'pae' está oculto
          } else if (this.formData.paymentMethod === 'pae') {
            this.showPayPalButtons = false;
            this.showClipButton = false;
            this.showPaeButton = true; // Muestra los botones de Pae si el método de pago es Pae
          } else {
            this.showPayPalButtons = false;
            this.showClipButton = false;
            this.showPaeButton = false; // Oculta todos los botones si el método de pago no es válido
          }
        }

        console.log(this.showValidationMessage);
        console.log(this.formData.paymentMethod);
      }
    },
    watch: {
      formData: {
        handler() {
          this.validateForm();
        },
        deep: true
      }
    },
    created() {
      const carritoLocal = localStorage.getItem('carrito');
      if (carritoLocal) {
        this.$store.commit('setProductosCarrito', JSON.parse(carritoLocal));
      }
    },
    async mounted() {
      this.store = this.$route.params.tienda;
      try {
        console.log('Nombre Tienda:', this.store);
        const metodosDePago  = await AuthService.metodosDePago(this.store);
        console.log("metodosDePago:",metodosDePago);
        this.metodosDePago = metodosDePago;
        console.log("metodosDePago[]:",this.metodosDePago);
        this.metodosFiltrados = metodosDePago.filter(metodo => metodo.activo);
      
      const paypalMethod = this.metodosFiltrados.find(metodos => metodos.metodo === 'PayPal');
    
    //    const paypalMethod = metodosDePago.find(metodos => metodos.metodo === 'paypal');
        if (paypalMethod) {
          this.paypalUser = paypalMethod.usuario; // Obtiene el usuario del método de pago PayPal
          console.log("Usuario de PayPal obtenido:", this.paypalUser);
        } else {
          console.error("No se encontró el método de pago PayPal.");
        }
         
      } catch (error) {
        console.error("Error al obtener métodos de pago:", error);
      }
    },
    components: {
      PayPalButtons,
      ClipButton
    }
  };
</script>
<style scoped>
/*
.paypal-button-container {
  display: none;  
}

.show-paypal-button .paypal-button-container {
  display: block;  
}

.form-control:invalid {
    border-color: #dc3545;
}*/
.invalid-feedback {
    display: block; 
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
/*
.form-control:valid {
    border-color: #28a745;
}*/
</style>