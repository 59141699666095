<template>
        <div class="container">
          <div class="row justify-content-center mb-md-6">
            <div class="col-auto">
              <a href="index.html">
                <img src="../assets/img/logo-1.jpg" alt="Saldi">
              </a>
            </div>
          </div>
          <div class="row text-center py-6">
            <div class="col layer-2">
              <h1 class="display-1 mb-0">Gracias por realizar tu compra con nosotros</h1>
              <h2 class="h1">Tu nunmero de orden es: {{numOrden}}</h2>
              <div class="lead mb-4">En un momento recibirar un correo electronico con los etalles de tu compra</div>
              O puedes rastrear tu pedido ->
              <a class="btn btn-primary btn-lg" href="/">aqui!!</a>
            </div>
          </div>
        </div>
    </template>

<script>
 
export default {
  data() {
    return {
      numOrden: ''
    
      };
  },
  mounted() {
    this.numOrden = this.$route.params.orden;
    localStorage.clear();
  }
};
</script>
