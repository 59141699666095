
<template>
    <div class="body">
        <nav class="custom-navbar navbar navbar navbar-expand-md navbar-dark bg-dark" arial-label="Furni navigation bar">
        <div class="container">
            <a class="navbar-brand" :href="`/${nombreTienda}`">
                {{ nombreTienda ? nombreTienda.charAt(0).toUpperCase() + nombreTienda.slice(1) : '' }}<span>.</span>
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsFurni" aria-controls="navbarsFurni" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarsFurni">
                <ul class="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
                    <li class="nav-item ">
                        <a class="nav-link" href="/">Inicio</a>
                    </li>
                    <!--
                    <li class="active"><a class="nav-link" href="shop.html">Shop</a></li>
                    <li><a class="nav-link" href="about.html">About us</a></li>
                    <li><a class="nav-link" href="services.html">Services</a></li>
                    <li><a class="nav-link" href="blog.html">Blog</a></li>
                    <li><a class="nav-link" href="contact.html">Contact us</a></li>
                    -->
                </ul>

                <ul class="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
                    <li><a class="nav-link" href="cart.html"><img src="../../../assets/img/theme1/cart.svg"></a></li>
                </ul>
            </div>
        </div>
    </nav>

    <!-- Start Hero Section -->
    <div class="hero">
        <div class="container">
        <div class="row justify-content-between">
				<div class="col-lg-5">
					<div class="intro-excerpt">
						<h1>{{tituloTienda}}</h1>
					</div>
				</div>
				<div class="col-lg-7">
						
				</div>
			</div>
		</div>
	</div>
    <!-- End Hero Section -->
    <div class="untree_co-section product-section before-footer-section">
	<div class="container">
	<div class="row">
	<!-- Start Column 1 -->
			<div class="col-12 col-md-4 col-lg-3 mb-5">
					<a class="product-item" href="#">
			<img src="../../../assets/img/theme1/product-3.png" class="img-fluid product-thumbnail">
						<h3 class="product-title">Nordic Chair</h3>
						<strong class="product-price">$50.00</strong>
						<span class="icon-cross">
							<img src="../../../assets/img/theme1/cross.svg" class="img-fluid">
						</span>
					</a>
				</div> 
				<!-- End Column 1 -->
						
				<!-- Start Column 2 -->
				<div class="col-12 col-md-4 col-lg-3 mb-5">
					<a class="product-item" href="#">
						<img src="../../../assets/img/theme1/product-1.png" class="img-fluid product-thumbnail">
						<h3 class="product-title">Nordic Chair</h3>
						<strong class="product-price">$50.00</strong>

						<span class="icon-cross">
							<img src="../../../assets/img/theme1/cross.svg" class="img-fluid">
						</span>
					</a>
				</div> 
				<!-- End Column 2 -->

				<!-- Start Column 3 -->
				<div class="col-12 col-md-4 col-lg-3 mb-5">
					<a class="product-item" href="#">
						<img src="../../../assets/img/theme1/product-2.png" class="img-fluid product-thumbnail">
						<h3 class="product-title">Kruzo Aero Chair</h3>
						<strong class="product-price">$78.00</strong>

						<span class="icon-cross">
							<img src="../../../assets/img/theme1/cross.svg" class="img-fluid">
						</span>
					</a>
				</div>
				<!-- End Column 3 -->

				<!-- Start Column 4 -->
				<div class="col-12 col-md-4 col-lg-3 mb-5">
					<a class="product-item" href="#">
						<img src="../../../assets/img/theme1/product-3.png" class="img-fluid product-thumbnail">
						<h3 class="product-title">Ergonomic Chair</h3>
						<strong class="product-price">$43.00</strong>

						<span class="icon-cross">
							<img src="../../../assets/img/theme1/cross.svg" class="img-fluid">
						</span>
					</a>
				</div>
				<!-- End Column 4 -->

                <!-- Start Column 1 -->
                <div class="col-12 col-md-4 col-lg-3 mb-5">
                    <a class="product-item" href="#">
                        <img src="../../../assets/img/theme1/product-3.png" class="img-fluid product-thumbnail">
                        <h3 class="product-title">Nordic Chair</h3>
                        <strong class="product-price">$50.00</strong>

                        <span class="icon-cross">
                            <img src="../../../assets/img/theme1/cross.svg" class="img-fluid">
                        </span>
                    </a>
                </div> 
				<!-- End Column 1 -->
						
				<!-- Start Column 2 -->
				<div class="col-12 col-md-4 col-lg-3 mb-5">
					<a class="product-item" href="#">
						<img src="../../../assets/img/theme1/product-1.png" class="img-fluid product-thumbnail">
						<h3 class="product-title">Nordic Chair</h3>
						<strong class="product-price">$50.00</strong>

						<span class="icon-cross">
							<img src="../../../assets/img/theme1/cross.svg" class="img-fluid">
						</span>
					</a>
				</div> 
				<!-- End Column 2 -->

				<!-- Start Column 3 -->
				<div class="col-12 col-md-4 col-lg-3 mb-5">
					<a class="product-item" href="#">
						<img src="../../../assets/img/theme1/product-2.png" class="img-fluid product-thumbnail">
						<h3 class="product-title">Kruzo Aero Chair</h3>
						<strong class="product-price">$78.00</strong>
						<span class="icon-cross">
							<img src="../../../assets/img/theme1/cross.svg" class="img-fluid">
						</span>
					</a>
				</div>
				<!-- End Column 3 -->

				<!-- Start Column 4 -->
				<div class="col-12 col-md-4 col-lg-3 mb-5">
					<a class="product-item" href="#">
						<img src="../../../assets/img/theme1/product-3.png" class="img-fluid product-thumbnail">
						<h3 class="product-title">Ergonomic Chair</h3>
						<strong class="product-price">$43.00</strong>

						<span class="icon-cross">
							<img src="../../../assets/img/theme1/cross.svg" class="img-fluid">
						</span>
					</a>
				</div>
    <!-- End Column 4 -->	  	</div>
		</div>
	</div>
    <!--Footer-->
    <footer class="footer-section">
			<div class="container relative">

				<div class="sofa-img">
					<img src="../../../assets/img/theme1/sofa.png" alt="Image" class="img-fluid">
				</div>

				<div class="row">
					<div class="col-lg-8">
						<div class="subscription-form">
							<h3 class="d-flex align-items-center"><span class="me-1"><img src="../../../assets/img/theme1/envelope-outline.svg" alt="Image" class="img-fluid"></span><span>Subscribe to Newsletter</span></h3>

							<form action="#" class="row g-3">
								<div class="col-auto">
									<input type="text" class="form-control" placeholder="Enter your name">
								</div>
								<div class="col-auto">
									<input type="email" class="form-control" placeholder="Enter your email">
								</div>
								<div class="col-auto">
									<button class="btn btn-primary">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                                        </svg>
									</button>
								</div>
							</form>

						</div>
					</div>
				</div>

				<div class="row g-5 mb-5">
					<div class="col-lg-4">
						<div class="mb-4 footer-logo-wrap"><a href="#" class="footer-logo">Furni<span>.</span></a></div>
						<p class="mb-4">Donec facilisis quam ut purus rutrum lobortis. Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique. Pellentesque habitant</p>

						<ul class="list-unstyled custom-social">
							<li>
                                <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                                </svg>
                                </a>
                            </li>
							<li>
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                    </svg>
                                </a>
                            </li>
							<li>
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                    </svg>
                                </a>
                            </li>
							<li>
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                    </svg>
                                </a>
                            </li>
						</ul>
					</div>

					<div class="col-lg-8">
						<div class="row links-wrap">
							<div class="col-6 col-sm-6 col-md-3">
								<ul class="list-unstyled">
									<li><a href="#">About us</a></li>
									<li><a href="#">Services</a></li>
									<li><a href="#">Blog</a></li>
									<li><a href="#">Contact us</a></li>
								</ul>
							</div>

							<div class="col-6 col-sm-6 col-md-3">
								<ul class="list-unstyled">
									<li><a href="#">Support</a></li>
									<li><a href="#">Knowledge base</a></li>
									<li><a href="#">Live chat</a></li>
								</ul>
							</div>

							<div class="col-6 col-sm-6 col-md-3">
								<ul class="list-unstyled">
									<li><a href="#">Jobs</a></li>
									<li><a href="#">Our team</a></li>
									<li><a href="#">Leadership</a></li>
									<li><a href="#">Privacy Policy</a></li>
								</ul>
							</div>

							<div class="col-6 col-sm-6 col-md-3">
								<ul class="list-unstyled">
									<li><a href="#">Nordic Chair</a></li>
									<li><a href="#">Kruzo Aero</a></li>
									<li><a href="#">Ergonomic Chair</a></li>
								</ul>
							</div>
						</div>
					</div>

				</div>

				<div class="border-top copyright">
					<div class="row pt-4">
						<div class="col-lg-6">
							<p class="mb-2 text-center text-lg-start">Copyright ©2024. All Rights Reserved. — Designed with love by <a href="https://untree.co">Untree.co</a>  Distributed By <a href="https://themewagon.com">ThemeWagon</a> <!-- License information: https://untree.co/license/ -->
            </p>
						</div>

						<div class="col-lg-6 text-center text-lg-end">
							<ul class="list-unstyled d-inline-flex ms-auto">
								<li class="me-4"><a href="#">Terms &amp; Conditions</a></li>
								<li><a href="#">Privacy Policy</a></li>
							</ul>
						</div>

					</div>
				</div>

			</div>
		</footer>
    </div>
</template>
<script>
export default {
    props: ['idTienda', 'nombreTienda', 'tituloTienda', 'subTituloTienda']
}
</script>
<style scoped>
.body {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 28px;
    color: #6a6a6a; 
    font-size: 14px;
    background-color: #eff2f1;
    overflow-x: hidden;
    position: relative;
}
.custom-navbar .custom-navbar-nav li.active a {
    opacity: 1;
}
.custom-navbar {
    background: #3b5d50 !important;
    padding-top: 20px;
    padding-bottom: 20px;
}
@media (min-width: 768px) {
    .custom-navbar .custom-navbar-nav li a:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 8px;
        right: 8px;
        background: #f9bf29;
        height: 5px;
        opacity: 1;
        visibility: visible;
        width: 0;
        -webkit-transition: .15s all ease-out;
        -o-transition: .15s all ease-out;
        transition: .15s all ease-out;
    }
}
@media (min-width: 992px) {
    .custom-navbar .custom-navbar-nav li {
        margin-left: 15px;
        margin-right: 15px;
    }

    .hero {
        background: #3b5d50;
        padding: calc(8rem - 30px) 0 8rem 0;
    }
}
/*
@media (min-width: 768px) {
    .hero {
        padding: calc(4rem - 30px) 0 4rem 0;
    }
}
.hero {
    background: #3b5d50;
    padding: calc(4rem - 30px) 0 0rem 0;
}*/
.hero h1 {
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 30px;
}
.product-section .product-item {
    text-align: center;
    text-decoration: none;
    display: block;
    position: relative;
    padding-bottom: 50px;
    cursor: pointer;
}
.product-section .product-item h3, .product-section .product-item strong {
    color: #2f2f2f;
    text-decoration: none;
}

.product-section .product-item h3 {
    font-weight: 600;
    font-size: 16px;
}

.product-section .product-item strong {
    font-weight: 800 !important;
    font-size: 18px !important;
}
.untree_co-section {
    padding: 7rem 0; 
}

.before-footer-section {
 padding: 7rem 0 12rem 0 !important; 
}
.product-section {
    padding: 7rem 0;
}
a:hover {
    color: #2f2f2f;
    text-decoration: none;
}
.product-section .product-item .icon-cross img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); 
}

.product-section .product-item:before {
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      content: "";
      background: #eff2f1;
      height: 0%;
      z-index: -1 ;
      border-radius: 10px;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; 
    }
    .product-section .product-item:hover .product-thumbnail {
      top: -25px; 
    }
    .product-section .product-item:hover .icon-cross {
      bottom: 0;
      opacity: 1;
      visibility: visible; 
    }
    .product-section .product-item:hover:before {
      height: 70%; 
    }
    .product-section .product-item:hover .icon-cross {
    bottom: 0;
    opacity: 1;
    visibility: visible;
}

.product-section .product-item .icon-cross {
    position: absolute;
    width: 35px;
    height: 35px;
    display: inline-block;
    background: #2f2f2f;
    bottom: 15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-bottom: -17.5px;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}
/** */
.footer-section {
    padding: 80px 0;
    background: #ffffff;
}
.footer-section .relative {
    position: relative;
}
.footer-section .sofa-img {
    position: absolute;
    top: -200px;
    z-index: 1;
    right: 0;
}
.footer-section .sofa-img img {
    max-width: 380px;
}
@media (min-width: 992px) {
    .footer-section .subscription-form {
        margin-top: 0px;
        margin-bottom: 80px;
    }
}
.footer-section .subscription-form {
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
    margin-top: 100px;
}
.footer-section .subscription-form h3 {
    font-size: 18px;
    font-weight: 500;
    color: #3b5d50;
}
.footer-section .footer-logo-wrap .footer-logo {
    font-size: 32px;
    font-weight: 500;
    text-decoration: none;
    color: #3b5d50;
}
.footer-section a {
    text-decoration: none;
    color: #2f2f2f;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}
.footer-section .border-top.copyright {
    font-size: 14px !important;
}

.footer-section .border-top {
    border-color: #dce5e4;
}
.footer-section .subscription-form .btn {
    border-radius: 10px !important;
}

.btn.btn-primary {
    background: #3b5d50;
    border-color: #3b5d50;
}
.footer-section .subscription-form .form-control {
    height: 50px;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
}
.form-control {
    height: 50px;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
}
.footer-section .custom-social li a {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    display: inline-block;
    background: #dce5e4;
    color: #3b5d50;
    border-radius: 50%;
}
.footer-section .custom-social li {
    margin: 2px;
    display: inline-block;
}
.footer-section .custom-social li a:hover {
    background: #3b5d50;
    color: #ffffff;
}
</style>